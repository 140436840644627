* {
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    box-sizing: border-box;
    color: black;
    font-smooth: always;
}

body, html, #screen {
    margin: 0px;
    min-height: 100vh;
    width: 100%;
    background: #F7F7F8;
}

body {
    max-width: 1920px;
    margin-left: auto;
    margin-right: auto;
    background-image: url("components/bg-stars.svg");
    content: '';
    top: 0;
    z-index: 100;
}

p {
    max-width: 100%;
    overflow-wrap: break-word;
}

.loader-svg {
    height: 200px;
    width: 200px;
}

.loader {
    position: fixed;
    height: 100%;
    width: 100vw;
    top: 0;
    right: 0;
    background: #00000044;
    z-index: 9999;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.Game {
    background: #F7F7F8;
    min-height: calc(100vh - 56px);
    height: 100%;
    padding-top: 25px;
}

@media (max-width: 450px) {
    .gamesec {
        border-radius: 0 !important;
        box-shadow: none !important;

    }

    .Game {
        padding-top: 0;
    }

    .game-title {
        font-size: 20px !important;
    }

    .gametab {
        min-width: 100vw !important;
        max-width: 500px;
        width: 100% !important;
    }
}

.gametab {
    width: 100%;
    max-width: 500px;
    width: 450px;
    position: relative;
}

.gamesec {
    background: #FFFFFF;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08), 0px 20px 32px rgba(96, 97, 112, 0.24);
    border-radius: 12px;
    padding: 22px;
    position: relative;
    z-index: 2;
}

.ranCont {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    border-bottom: 1px solid #f0f0f0;
}

.ranNum {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.tophalf {
    display: flex;
    justify-content: space-between;
}

.gametxtinput {
    background: white;
    height: 48px;
    border: 1.5px solid #E2E5E9;
    border-radius: 8px;
}

.gameinput.b {
    padding-bottom: 30px;
    border-bottom: 1px solid #f0f0f0;
}

.gamean {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
}

.amsel {
    display: flex;
    justify-content: space-between;
}

.amselinp {
    padding: 6px 2px;
    border: 1px solid #E2E5E9;
    border-radius: 8px;
    width: 23%;
}

.smlogimg {
    height: 22px;
}

.inpboxg {
    position: relative;
}

.onenum {
    height: 72px;
    width: 23%;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    font-size: 48px;
    background: #F7F7F8;
    border-radius: 8px;
    font-family: 'Work Sans';
}

.game-title {
    font-size: 24px;
    color: rgba(28, 28, 40, 0.87);
    margin-bottom: 15px;
}

.gameInp {
    display: flex;
    justify-content: space-around;
}

.gameInputs {
    display: flex;
    justify-content: space-between;
}

.gametxtinp {
    background: #EFEFEF;
    height: 48px;
    border: 0;
    outline: 0;
    border-radius: 10px;
}

.betamount, .winnings {
    width: 100%;
}

.multiplier {
    width: 16%;
}

.avimg {
    height: 36px;
    width: 36px;
}

.mult, .eq {
    width: 5%;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
}


.Game {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    min-height: 100%;
}

.gameInpCont {
    width: 80%;
}

.gameBtns {
    display: flex;
    justify-content: space-between;
}

.gameBtns button {
    width: 48%;
    height: 48px;
    background: #FFFFFF;
    border: 1px solid #ee5e1d;
    border-radius: 8px;
    font-size: 18px;
    color: #ee5e1d;
    font-weight: 500;
    outline: 0;
    font-family: 'Work Sans';
    transition: 0.2s;
    cursor: pointer;
    position: relative;
}

.btn.hi.Approve, .btn.hi.Approving {
    display: none;
}

.btn.lo.Approve, .btn.lo.Approving {
    width: 100%;
}

.gameBtns button.l.Approve, .gameBtns button.l.Approving {
    display: none;
}

.gameBtns button.r.Approve, .gameBtns button.r.Approving {
    width: 100%;
}

.lnkss {
    display: flex;
    justify-content: space-between;
}

.btn:disabled:hover {
    background: #83b5ff;
    cursor: default;
}

.stakebtn.ico:disabled {
    background: #83b5ff;
}

.txwait {
    height: 30px;
    width: 30px;
    opacity: 0;
}

.txwait.Playing, .txwait.Approving, .txwait.Staking, .txwait.Unstaking, .txwait.Buying, .txwait.Claiming, .txwait.Swapping {
    opacity: 1;
}

.gameBtns button:hover {
    background: #ee5e1d;
    color: white;
}

.stakemore {
    display: flex;
    justify-content: space-between;
}

@media (max-width: 350px) {
    .gameBtns button .txwait {
        display: none !important;
    }
}


.stakebtn.big {
    height: 48px;
    width: 100%;
    position: relative;
}

.btn.Staking, .btn.Playing, .btn.Unstaking, .btn.Approving, .btn.Buying, .btn.Claiming, .btn.Swapping {
    animation-name: active;
    animation-duration: 1.3s;
    animation-iteration-count: infinite;
    color: white;
}


.gameBtns .btn.Waiting {
    background: #83b5ff;
    color: #b0b0b0;
}

.btn.Waiting {
    background: #83b5ff;
}

.gameBtns .btn.Waiting {
    background: white;
    border: #c0c0c0 1px solid;
    color: #c0c0c0 !important;
}

.bg-gr {
    background-color: #A3D37D;
    color: #3D6120;
    padding: 15px;
    font-size: 16px;
}

.bg-red {
    background: #EAB0B0;
    color: #990202;
    padding: 15px;
    font-size: 16px;
}

.bg-blue {
    background: #EEF6FF;
    color: #ee5e1d;
    padding: 15px;
    font-size: 16px;
}

.onenum.one.Playing::after {
    animation-name: playingone;
    animation-duration: 0.2s;
    animation-iteration-count: infinite;
    content: "0";
}

.onenum.two.Playing::after {
    animation-name: playingtwo;
    animation-duration: 0.1s;
    animation-iteration-count: infinite;
    content: "0";
}

.onenum.three.Playing::after {
    animation-name: playingthree;
    animation-duration: 0.1s;
    animation-iteration-count: infinite;
    content: "0";
}

.onenum.four.Playing::after {
    animation-name: playingfour;
    animation-duration: 0.2s;
    animation-iteration-count: infinite;
    content: "0";
}


@keyframes active {
    0% {
        background: #ee5e1d;
    }
    50% {
        background: hsl(19, 86%, 52%);
    }
    100% {
        background: #ee5e1d;
    }
}

@keyframes playingone {
    0% {
        content: "0";
    }
    10% {
        content: "1";
    }
    20% {
        content: "2";
    }
    30% {
        content: "3";
    }
    40% {
        content: "4";
    }
    50% {
        content: "5";
    }
    60% {
        content: "6";
    }
    70% {
        content: "7";
    }
    80% {
        content: "8";
    }
    90% {
        content: "9";
    }
    100% {
        content: "0";
    }
}

@keyframes playingtwo {
    0% {
        content: "0";
    }
    10% {
        content: "8";
    }
    20% {
        content: "9";
    }
    30% {
        content: "6";
    }
    40% {
        content: "7";
    }
    50% {
        content: "5";
    }
    60% {
        content: "3";
    }
    70% {
        content: "4";
    }
    80% {
        content: "1";
    }
    90% {
        content: "0";
    }
    100% {
        content: "2";
    }
}

@keyframes playingthree {
    0% {
        content: "0";
    }
    10% {
        content: "9";
    }
    20% {
        content: "8";
    }
    30% {
        content: "7";
    }
    40% {
        content: "6";
    }
    50% {
        content: "5";
    }
    60% {
        content: "4";
    }
    70% {
        content: "3";
    }
    80% {
        content: "2";
    }
    90% {
        content: "1";
    }
    100% {
        content: "0";
    }
}

@keyframes playingfour {
    0% {
        content: "3";
    }
    10% {
        content: "8";
    }
    20% {
        content: "1";
    }
    30% {
        content: "2";
    }
    40% {
        content: "9";
    }
    50% {
        content: "0";
    }
    60% {
        content: "6";
    }
    70% {
        content: "4";
    }
    80% {
        content: "5";
    }
    90% {
        content: "7";
    }
    100% {
        content: "0";
    }
}

@media (max-width: 500px) {
    .playToEarn {
        font-size: 24px;
        text-align: center;
    }
}

.playToEarn {
    text-align: center;
}